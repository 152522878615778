import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useEffect } from 'react';
import { useTransition } from 'react-transition-state';
import classNames from '../utilities/class-names';
import './Loading.scss';

type LoadingProps = {
  loading?: boolean;
};

export function Loading({ loading = true }: LoadingProps): ReactElement {
  const [state, toggle] = useTransition({
    timeout: 200,
    preEnter: true,
    initialEntered: loading,
  });

  useEffect(() => {
    toggle(loading);
  }, [toggle, loading]);

  return (
    <div className={classNames('loading', state.status)}>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  );
}
