import { ReactElement, ReactNode } from 'react';
import classNames from '../utilities/class-names';
import './Card.scss';

type CardProps = {
  className?: string;
  children?: ReactNode;
  background?: boolean;
  [key: string]: any;
};

export function Card({
  className,
  children,
  background = true,
  ...props
}: CardProps): ReactElement {
  return (
    <div
      className={classNames(
        'card',
        background ? 'card-background' : '',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
