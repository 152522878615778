import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import {
  Button,
  Card,
  ConfigurationStepTypeTag,
  JsonViewer,
  Value,
  ValueList,
} from '.';
import { ConfigurationStep } from '../types';
import './ConfigurationStepCard.scss';

type ConfigurationStepCardProps = {
  index: number;
  step: ConfigurationStep;
  [key: string]: any;
};

export function ConfigurationStepCard({
  index,
  step,
  ...props
}: ConfigurationStepCardProps): ReactElement {
  return (
    <Card className="configuration-step-card" {...props}>
      <h3>
        {step.type}
        <span className="extra">Step {index + 1}</span>
      </h3>
      <ValueList>
        <Value
          className="configuration-step-card-type"
          label="Type"
          value={
            <>
              <ConfigurationStepTypeTag stepType={step.type} />
              <Button
                className="configuration-step-card-help-button"
                icon={faQuestionCircle}
                link="/documentation"
                linkHash={step.type}
                linkOpensNewTab
                minimal
              />
            </>
          }
        />
        {step.label && <Value label="Label" value={step.label} />}
        <Value label="Parameters" value={<JsonViewer src={step.params} />} />
      </ValueList>
    </Card>
  );
}
