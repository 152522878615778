import {
  faAngleDown,
  faAngleUp,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Field, Form, Formik } from 'formik';
import { ReactElement, useCallback, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { Button, SearchResultsMenu, Tag } from '.';
import { useEffectOnce } from '../hooks/effect-once';
import useSearch from '../hooks/search';
import { SearchResults } from '../types/search-result';
import classNames from '../utilities/class-names';
import './SearchBar.scss';

export function SearchBar(): ReactElement {
  const { pending, error, resetError, search } = useSearch();
  const [searchQueryParam, setSearchQueryParam] = useQueryParam(
    'query',
    StringParam
  );
  const [searchQuery, setSearchQuery] = useState(searchQueryParam ?? '');
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResults | null>(
    null
  );

  const fetchSearchResults = useCallback(
    async (signal?: AbortSignal) => {
      const response = await search(searchQuery, signal);

      if (response) {
        setSearchResults(response);
      }
    },
    [search, searchQuery]
  );

  const performSearch = useCallback(
    async (query: string) => {
      setSearchQueryParam(query);
      await fetchSearchResults();
      setSearchResultsVisible(true);
    },
    [setSearchQueryParam, fetchSearchResults]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target?.value ?? '');
      resetError();
    },
    [setSearchQuery, resetError]
  );

  const handleReset = useCallback(() => {
    setSearchQueryParam(undefined);
    setSearchQuery('');
    setSearchResults(null);
    setSearchResultsVisible(false);
    resetError();
  }, [setSearchQueryParam, setSearchResults, resetError]);

  const handleSubmit = useCallback(async () => {
    if (!searchQuery) {
      return;
    }

    await performSearch(searchQuery);
  }, [performSearch, searchQuery]);

  useEffectOnce(() => {
    if (searchQueryParam) {
      performSearch(searchQueryParam);
    }
  });

  const searchResultsMenuOpen =
    searchResultsVisible &&
    searchResults !== null &&
    searchResults.results.length > 0;

  return (
    <div className="search-bar">
      <Formik
        initialValues={{
          query: searchQuery,
        }}
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false);
          handleSubmit();
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              type="text"
              name="query"
              placeholder="Search..."
              onChange={handleChange}
              className={classNames(searchQuery ? 'has-value' : '')}
              maxLength={100}
              autoComplete="off"
              value={searchQuery || ''}
            />
            <Button
              type="reset"
              className={classNames(
                'reset-search-button',
                searchQuery ? 'has-value' : ''
              )}
              icon={faTimes}
              onClick={handleReset}
              disabled={!searchQuery && !searchResults}
              secondary
            />
            <Button
              type="submit"
              className="submit-search-button"
              icon={faSearch}
              loading={pending}
              disabled={!searchQuery || isSubmitting}
              secondary
            />
            <Tag
              intent={
                searchResults !== null && searchResults.results.length > 0
                  ? 'success'
                  : 'warning'
              }
              show={searchResults !== null}
              icon={
                searchResults !== null && searchResults.results.length > 0
                  ? searchResultsMenuOpen
                    ? faAngleUp
                    : faAngleDown
                  : null
              }
              onClick={() => {
                setSearchResultsVisible(!searchResultsVisible);
              }}
            >
              {searchResults !== null && searchResults.results.length > 0
                ? `${searchResults.results.length} result${
                    searchResults.results.length === 1 ? '' : 's'
                  }`
                : 'No results'}
            </Tag>
            <Tag intent="danger" show={!!error}>
              {error ? 'Invalid query' : ''}
            </Tag>
          </Form>
        )}
      </Formik>
      <SearchResultsMenu
        items={searchResults?.results ?? []}
        show={searchResultsMenuOpen}
      />
    </div>
  );
}
