import {
  IconDefinition,
  faUserAlt,
  faUserNinja,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { Intent, UserType } from '../types';
import classNames from '../utilities/class-names';

type UserTypeTagStyle = {
  icon: IconDefinition;
  intent: Intent;
  className: string;
  label: string;
};

const userTypeTagStyles: {
  [key in 'user' | 'admin']: UserTypeTagStyle;
} = {
  user: {
    icon: faUserAlt,
    intent: 'info',
    className: 'user-type-tag-user',
    label: 'User',
  },
  admin: {
    icon: faUserNinja,
    intent: 'warning',
    className: 'user-type-tag-admin',
    label: 'Admin',
  },
};

type UserTypeTagProps = {
  type: UserType;
};

export function UserTypeTag({ type }: UserTypeTagProps): ReactElement {
  let { icon, intent, className, label } = userTypeTagStyles[type];

  return (
    <Tag
      show
      className={classNames('user-type-tag', className)}
      intent={intent}
      icon={icon}
    >
      {label}
    </Tag>
  );
}
