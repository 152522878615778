import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import useTransition from 'react-transition-state';
import classNames from '../utilities/class-names';
import './Collapse.scss';

type CollapseProps = {
  className?: string;
  children?: ReactNode;
  closable?: boolean;
  background?: boolean;
  expanded?: boolean;
  onCollapse?: () => void;
  [key: string]: any;
};

export function Collapse({
  className,
  children,
  closable,
  background = true,
  expanded,
  onExpand,
  onCollapse,
  ...props
}: CollapseProps): ReactElement {
  const [state, toggle] = useTransition({
    timeout: 200,
    preEnter: true,
    initialEntered: expanded,
  });

  useEffect(() => {
    toggle(expanded);
  }, [toggle, expanded]);

  const close = useCallback(() => {
    toggle(false);
    onCollapse?.();
  }, [toggle, onCollapse]);

  return (
    <div
      className={classNames(
        'collapse',
        background ? 'collapse-background' : '',
        className,
        state.status
      )}
      {...props}
    >
      {closable && (
        <button className="collapse-close-button" onClick={() => close()}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
      {children}
    </div>
  );
}
