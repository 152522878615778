import { ReactElement, ReactNode } from 'react';
import classNames from '../utilities/class-names';
import './CardList.scss';

type CardListProps = {
  className?: string;
  children?: ReactNode;
  horizontal?: boolean;
  [key: string]: any;
};

export function CardList({
  className,
  children,
  horizontal,
  ...props
}: CardListProps): ReactElement {
  return (
    <div
      className={classNames(
        'card-list',
        horizontal ? 'card-list-horizontal' : '',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
