import {
  IconDefinition,
  faCheck,
  faExclamation,
  faInfo,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { useTransition } from 'react-transition-state';
import { Intent } from '../types/intent';
import classNames from '../utilities/class-names';
import './Tag.scss';

type TagStyle = {
  icon: IconDefinition;
  className: string;
};

const tagStyles: {
  [key in Intent]: TagStyle;
} = {
  info: {
    icon: faInfo,
    className: 'tag-info',
  },
  success: {
    icon: faCheck,
    className: 'tag-success',
  },
  warning: {
    icon: faExclamation,
    className: 'tag-warning',
  },
  danger: {
    icon: faTimes,
    className: 'tag-danger',
  },
};

type TagProps = {
  className?: string;
  children?: ReactNode;
  intent?: Intent;
  colour?: string;
  icon?: IconDefinition | null;
  shake?: boolean;
  pulse?: boolean;
  jump?: boolean;
  show?: boolean;
  closable?: boolean;
  onClick?: () => void;
  onClose?: () => void;
};

export function Tag({
  className,
  children,
  intent,
  colour,
  icon,
  shake,
  pulse,
  jump,
  show,
  closable,
  onClick,
  onClose,
}: TagProps): ReactElement {
  const [state, toggle] = useTransition({
    timeout: 200,
    preEnter: true,
    initialEntered: show,
  });

  useEffect(() => {
    toggle(show);
  }, [toggle, show]);

  const close = useCallback(() => {
    toggle(false);
    onClose?.();
  }, [toggle, onClose]);

  let style: TagStyle | null = null;
  if (intent) {
    style = tagStyles[intent];
  }
  const actualIcon = icon ?? style?.icon;

  return (
    <div
      className={classNames(
        'tag',
        className,
        style?.className ?? 'tag-none',
        state.status,
        shake ? 'tag-shake' : '',
        pulse ? 'tag-pulse' : '',
        jump ? 'tag-jump' : '',
        onClick ? 'tag-clickable' : '',
        closable ? 'tag-closable' : ''
      )}
      onClick={onClick}
      style={
        colour
          ? {
              backgroundColor: colour,
            }
          : {}
      }
    >
      {actualIcon && <FontAwesomeIcon icon={actualIcon} />}
      {children}
      {closable && (
        <button className="tag-close-button" onClick={() => close()}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </div>
  );
}
