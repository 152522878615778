import {
  IconDefinition,
  faGlobe,
  faLock,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { ConfigurationAccess, Intent } from '../types';
import classNames from '../utilities/class-names';

type ConfigurationAccessTagStyle = {
  icon: IconDefinition;
  intent: Intent;
  className: string;
  label: string;
};

const configurationAccessTagStyles: {
  [key in ConfigurationAccess]: ConfigurationAccessTagStyle;
} = {
  public: {
    icon: faGlobe,
    intent: 'info',
    className: 'configuration-access-tag-public',
    label: 'Public',
  },
  private: {
    icon: faLock,
    intent: 'danger',
    className: 'configuration-access-tag-private',
    label: 'Private',
  },
  protected: {
    icon: faShieldAlt,
    intent: 'warning',
    className: 'configuration-access-tag-protected',
    label: 'Protected',
  },
};

type ConfigurationAccessTagProps = {
  access: ConfigurationAccess;
};

export function ConfigurationAccessTag({
  access,
}: ConfigurationAccessTagProps): ReactElement {
  let { icon, intent, className, label } = configurationAccessTagStyles[access];

  return (
    <Tag
      show
      className={classNames('configuration-access-tag', className)}
      intent={intent}
      icon={icon}
    >
      {label}
    </Tag>
  );
}
