import { IconDefinition, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge } from '.';
import { Intent } from '../types';
import classNames from '../utilities/class-names';
import './MenuItem.scss';

type MenuItemProps = {
  icon?: IconDefinition;
  title?: string;
  link?: string;
  count?: number;
  tooltipId?: string;
};

function countToIntent(count: number): Intent {
  if (count > 4) {
    return 'danger';
  }

  if (count > 2) {
    return 'warning';
  }

  return 'success';
}

export function MenuItem({
  icon = faFile,
  title,
  link,
  count,
  tooltipId,
}: MenuItemProps): ReactElement {
  return (
    <NavLink
      to={link ?? '/'}
      className={({ isActive }) =>
        classNames('menu-item', isActive ? 'active' : undefined)
      }
      data-tooltip-id={tooltipId}
      data-tooltip-content={title ?? ''}
      data-tooltip-place="right"
    >
      <FontAwesomeIcon icon={icon} />
      <Badge intent={countToIntent(count ?? 0)} count={count} show={!!count} />
    </NavLink>
  );
}
