import { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';

export function RedirectExecuteConfiguration(): ReactElement {
  const { uid } = useParams();

  useEffect(() => {
    window.location.href = `${config.api.url}/execute/${uid}`;
  }, [uid]);

  return <></>;
}
