import { ReactElement } from 'react';
import {
  Bar,
  BarChart,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import config from '../config';
import formatDate from '../utilities/format-date';
import './Chart.scss';

type ChartProps = {
  type: 'line' | 'bar';
  metrics: {
    date: string;
    [key: string]: any;
  }[];
  colours?: {
    [key: string]: string;
  };
};

const formatChartDate = (date: string): string =>
  formatDate(date, config.chartDateFormat);

export function Chart({
  type,
  metrics,
  colours = {
    '*': '#fffcf2',
  },
}: ChartProps): ReactElement {
  const series = [
    ...new Set(metrics.flatMap(metric => Object.keys(metric))),
  ].filter(key => key !== 'date');

  const ChartTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, string>): ReactElement =>
    active ? (
      <div className="chart-tooltip">
        <table>
          <tbody>
            <tr>
              <td>date</td>
              <td>{formatChartDate(label)}</td>
            </tr>
            {series
              .map(s => [s, payload?.[0]?.payload?.[s]])
              .filter(([s, c]) => c !== undefined)
              .map(([s, c]) => (
                <tr
                  key={s}
                  style={{
                    color: colours[s] ?? colours['*'] ?? '#fffcf2',
                  }}
                >
                  <td>{s}</td>
                  <td>{c}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    ) : (
      <></>
    );

  const ChartContainer = type === 'line' ? LineChart : BarChart;

  return (
    <ResponsiveContainer className="chart" width={'100%'} height={300}>
      <ChartContainer
        data={metrics
          .map(metric => ({
            ...metric,
            date: new Date(metric.date),
          }))
          .sort((a, b) => a.date.getTime() - b.date.getTime())}
        margin={{ top: 20, right: 20, left: -30 }}
      >
        {type === 'line'
          ? series.map(s => (
              <Line
                key={s}
                type="monotone"
                dataKey={s}
                stroke={colours[s] ?? colours['*'] ?? '#fffcf2'}
                strokeWidth={2}
              />
            ))
          : series.map(s => (
              <Bar
                key={s}
                dataKey={s}
                stackId="a"
                fill={colours[s] ?? colours['*'] ?? '#fffcf2'}
              />
            ))}
        <XAxis
          dataKey="date"
          tick={{ fontSize: 12 }}
          tickFormatter={formatChartDate}
        />
        <YAxis tick={{ fontSize: 12 }} />
        <Tooltip content={<ChartTooltip />} cursor={type === 'line'} />
      </ChartContainer>
    </ResponsiveContainer>
  );
}
