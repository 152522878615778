import { EventType } from '../types/event';

const eventTypeColours: Record<EventType, string> = {
  system: '#ad71dc',
  unknown: '#b1aba1',
  test: '#fffcf2',
  'configuration-created': '#f0c04d',
  'configuration-updated': '#f0ad4e',
  'configuration-deleted': '#f0d34d',
  'configuration-executed': '#f08f4d',
  'user-created': '#637ee0',
  'user-updated': '#5bc0de',
  'user-deleted': '#5b96de',
  'user-logged-in': '#5cb85c',
  'user-logged-out': '#25b888',
  'setting-updated': '#d9534f',
};

export default eventTypeColours;
