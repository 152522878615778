import {
  faBook,
  faChartSimple,
  faFlask,
  faHome,
  faPeopleGroup,
  faVial,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement, useId } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { MenuItem } from '.';
import config from '../config';
import { useAuth } from '../hooks/auth';
import { useBulletin } from '../hooks/bulletin';
import './Menu.scss';

export function Menu(): ReactElement {
  const { user } = useAuth();

  const tooltipId = `menu-tooltip-${useId()}`;

  const { data: bulletinData } = useBulletin();

  return (
    <>
      <nav className="menu">
        <MenuItem
          icon={faHome}
          title="Dashboard"
          link="/dashboard"
          tooltipId={tooltipId}
        />
        {user?.admin && config.debug.showTestPage && (
          <MenuItem
            icon={faVial}
            title="Test"
            link="/test"
            tooltipId={tooltipId}
          />
        )}
        <MenuItem
          icon={faFlask}
          title="Configurations"
          link="/configurations"
          count={bulletinData?.configurations}
          tooltipId={tooltipId}
        />
        {user?.admin && (
          <MenuItem
            icon={faPeopleGroup}
            title="Users"
            link="/users"
            count={bulletinData?.users}
            tooltipId={tooltipId}
          />
        )}
        <MenuItem
          icon={faChartSimple}
          title="Events"
          link="/events"
          tooltipId={tooltipId}
        />
        {user?.admin && (
          <MenuItem
            icon={faWrench}
            title="Settings"
            link="/settings"
            tooltipId={tooltipId}
          />
        )}
        <MenuItem
          icon={faBook}
          title="Documentation"
          link="/documentation"
          tooltipId={tooltipId}
        />
      </nav>
      {createPortal(
        <Tooltip id={tooltipId} className="menu-tooltip" />,
        document.body
      )}
    </>
  );
}
