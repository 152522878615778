import { ReactElement, useEffect } from 'react';
import useTransition from 'react-transition-state';
import { SearchResultsMenuItem } from '.';
import { SearchResult } from '../types/search-result';
import classNames from '../utilities/class-names';
import './SearchResultsMenu.scss';

type SearchResultsMenuProps = {
  items: SearchResult[];
  show?: boolean;
};

export function SearchResultsMenu({
  items,
  show,
}: SearchResultsMenuProps): ReactElement {
  const [state, toggle] = useTransition({
    timeout: 200,
    preEnter: true,
    initialEntered: show,
  });

  useEffect(() => {
    toggle(show);
  }, [toggle, show]);

  return (
    <>
      <div className={classNames('search-results-menu', state.status)}>
        <div className="search-results-menu-arrow"></div>
        {items.map((item, i) => (
          <SearchResultsMenuItem key={i} item={item} />
        ))}
      </div>
    </>
  );
}
