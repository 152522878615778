import { useEffect, useRef } from 'react';

type Dependencies = any[] | undefined;

export function useEffectOnce(
  callback: (dependencies: Dependencies) => void,
  condition?: (dependencies: Dependencies) => boolean,
  dependencies?: Dependencies
): void {
  const calledOnce = useRef(false);

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    if (!condition || (dependencies && condition(dependencies))) {
      callback(dependencies);
      calledOnce.current = true;
    }
  }, [callback, condition, dependencies]);
}
