import { ReactElement } from 'react';
import { SingleValue } from 'react-select';
import { Checkbox, JsonEditor, Select, Switch } from '.';
import { Setting } from '../types/setting';
import { SelectOptionProps } from './Select';

export type SettingFieldProps = {
  setting: Setting;
  pending?: boolean;
  onChange?: (value: any) => void;
};

export function SettingField({
  setting,
  pending,
  onChange,
}: SettingFieldProps): ReactElement {
  switch (setting.component) {
    case 'Checkbox':
      return (
        <Checkbox
          id={`setting-field-${setting.key}`}
          name={`setting-field-${setting.key}`}
          value={!!setting.value}
          onChange={onChange}
          disabled={pending}
        />
      );

    case 'Switch':
      return (
        <Switch
          id={`setting-field-${setting.key}`}
          name={`setting-field-${setting.key}`}
          value={!!setting.value}
          onChange={onChange}
          loading={pending}
          disabled={pending}
        />
      );

    case 'TextInput':
      return (
        <input
          type="text"
          id={`setting-field-${setting.key}`}
          name={`setting-field-${setting.key}`}
          value={setting.value ?? ''}
          onChange={e => onChange?.(e.target.value)}
          disabled={pending}
        />
      );

    case 'TextArea':
      return (
        <textarea
          id={`setting-field-${setting.key}`}
          name={`setting-field-${setting.key}`}
          value={setting.value ?? ''}
          onChange={e => onChange?.(e.target.value)}
          disabled={pending}
        />
      );

    case 'NumericInput':
      return (
        <input
          type="number"
          id={`setting-field-${setting.key}`}
          name={`setting-field-${setting.key}`}
          value={setting.value ?? ''}
          onChange={e => onChange?.(e.target.value)}
          disabled={pending}
        />
      );

    case 'Select':
      const options: SelectOptionProps<string>[] =
        setting.schema?.enum?.map((value: string) => ({
          label: value,
          value,
        })) ?? [];

      return (
        <Select
          id={`setting-field-${setting.key}`}
          name={`setting-field-${setting.key}`}
          options={options}
          value={setting.value ?? ''}
          onChange={(selected: SingleValue<SelectOptionProps<string>>) => {
            if (selected) {
              onChange?.(selected.value);
            }
          }}
          disabled={pending}
        />
      );

    case 'JsonEditor':
      return (
        <JsonEditor
          name={`setting-field-${setting.key}`}
          value={setting.value}
          schema={setting.schema ?? undefined}
          allowEmpty
          onChange={(v: any, e?: string) => {
            if (!e) {
              onChange?.(v);
            }
          }}
          disabled={pending}
        />
      );

    default:
      return <></>;
  }
}
