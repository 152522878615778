import {
  IconDefinition,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faCalendarDays,
  faEnvelope,
  faFileLines,
  faKeyboard,
  faLink,
  faLocationDot,
  faMoon,
  faPenToSquare,
  faShuffle,
  faSquareCheck,
  faSquarePlus,
  faTabletScreenButton,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { ConfigurationStepType } from '../types';
import classNames from '../utilities/class-names';

type ConfigurationStepTypeTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
};

const configurationStepTypeTagStyles: {
  [key in ConfigurationStepType]: ConfigurationStepTypeTagStyle;
} = {
  CHECK_DATE: {
    icon: faCalendarDays,
    className: 'configuration-step-type-tag-check-date',
    label: 'Check date',
  },
  CHECK_INPUT: {
    icon: faKeyboard,
    className: 'configuration-step-type-tag-check-input',
    label: 'Check input',
  },
  CHECK_LOCAL: {
    icon: faSquareCheck,
    className: 'configuration-step-type-tag-check-local',
    label: 'Check local',
  },
  INCREMENT_LOCAL: {
    icon: faSquarePlus,
    className: 'configuration-step-type-tag-increment-local',
    label: 'Increment local',
  },
  LOCATION: {
    icon: faLocationDot,
    className: 'configuration-step-type-tag-location',
    label: 'Location',
  },
  LOG: {
    icon: faFileLines,
    className: 'configuration-step-type-tag-log',
    label: 'Log',
  },
  REDIRECT: {
    icon: faLink,
    className: 'configuration-step-type-tag-redirect',
    label: 'Redirect',
  },
  REQUEST: {
    icon: faArrowRightToBracket,
    className: 'configuration-step-type-tag-request',
    label: 'Request',
  },
  RESPONSE: {
    icon: faArrowRightFromBracket,
    className: 'configuration-step-type-tag-response',
    label: 'Response',
  },
  SEND_EMAIL: {
    icon: faEnvelope,
    className: 'configuration-step-type-tag-send-email',
    label: 'Send email',
  },
  SET_LOCAL: {
    icon: faPenToSquare,
    className: 'configuration-step-type-tag-set-local',
    label: 'Set local',
  },
  SLEEP: {
    icon: faMoon,
    className: 'configuration-step-type-tag-sleep',
    label: 'Sleep',
  },
  TEST: {
    icon: faVial,
    className: 'configuration-step-type-tag-test',
    label: 'Test',
  },
  USER_AGENT: {
    icon: faTabletScreenButton,
    className: 'configuration-step-type-tag-user-agent',
    label: 'User agent',
  },
  WEIGHTED_RANDOM: {
    icon: faShuffle,
    className: 'configuration-step-type-tag-weighted-random',
    label: 'Weighted random',
  },
};

type ConfigurationStepTypeTagProps = {
  stepType: ConfigurationStepType;
};

export function ConfigurationStepTypeTag({
  stepType,
}: ConfigurationStepTypeTagProps): ReactElement {
  let { icon, className, label } = configurationStepTypeTagStyles[stepType];

  return (
    <Tag
      show
      className={classNames('configuration-step-type-tag', className)}
      icon={icon}
    >
      {label}
    </Tag>
  );
}
