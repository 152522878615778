import {
  faClock,
  faUserAlt,
  faUserNinja,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Card, TaskBar, Value } from '.';
import config from '../config';
import { Event } from '../types';
import formatDate from '../utilities/format-date';
import './EventCard.scss';
import { EventTypeTag } from './EventTypeTag';

type EventCardProps = {
  event: Event;
  [key: string]: any;
};

export function EventCard({ event, ...props }: EventCardProps): ReactElement {
  return (
    <Card className="event-card" {...props}>
      <TaskBar
        left={
          <>
            <Value
              className="event-card-date"
              icon={faClock}
              value={
                <Link to={`/events/${event.id}`}>
                  {formatDate(event.createdAt, config.pageDateFormat)}
                </Link>
              }
              inline
              standalone
            />
            <Value
              className="event-card-user"
              icon={event?.user?.admin ? faUserNinja : faUserAlt}
              value={
                <Link to={`/users/${event?.user?.id}`}>
                  {event?.user?.displayName ?? ''}
                </Link>
              }
              inline
              standalone
            />
          </>
        }
        right={
          <>
            <Value
              className="event-card-type"
              value={<EventTypeTag eventType={event.type} />}
              inline
              standalone
            />
          </>
        }
        background={false}
      />
    </Card>
  );
}
