import { ReactElement } from 'react';
import { SearchBar, Title, UserMenu } from '.';
import './Header.scss';

type HeaderProps = {
  showSearchBar?: boolean;
  showUserMenu?: boolean;
};

export function Header({
  showSearchBar = true,
  showUserMenu = true,
}: HeaderProps): ReactElement {
  return (
    <header className="header">
      <Title />
      {showSearchBar && <SearchBar />}
      {showUserMenu && <UserMenu />}
    </header>
  );
}
