import { useCallback, useState } from 'react';
import config from '../config';
import { SearchResults } from '../types/search-result';
import { useAuth } from './auth';

const useSearch = () => {
  const { token } = useAuth();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  const search = useCallback(
    async (
      query: string,
      signal?: AbortSignal
    ): Promise<SearchResults | void> => {
      setPending(true);

      return fetch(`${config.api.url}/search?query=${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-token': token ?? '',
        },
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(await response.text()));
            return;
          }

          return (await response.json()) as SearchResults;
        })
        .catch(error => {
          setPending(false);

          if (error.name === 'AbortError') {
            return;
          }

          setError(error);
        });
    },
    [token]
  );

  return {
    pending,
    error,
    resetError,
    search,
  };
};

export default useSearch;
