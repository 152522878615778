import {
  IconDefinition,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ValueList } from '.';
import { Intent } from '../types';
import classNames from '../utilities/class-names';
import './Value.scss';

type ValueStyle = {
  icon: IconDefinition;
  className: string;
};

const valueStyles: {
  [key in Intent]: ValueStyle;
} = {
  info: {
    icon: faInfoCircle,
    className: 'value-info',
  },
  success: {
    icon: faCheckCircle,
    className: 'value-success',
  },
  warning: {
    icon: faExclamationTriangle,
    className: 'value-warning',
  },
  danger: {
    icon: faExclamationCircle,
    className: 'value-danger',
  },
};

type ValueProps = {
  className?: string;
  intent?: Intent;
  icon?: IconDefinition | null;
  label?: string;
  labelDescription?: string;
  value: any;
  valueDescription?: string;
  link?: string;
  htmlFor?: string;
  standalone?: boolean;
  inline?: boolean;
  numeric?: boolean;
  [key: string]: any;
};

export function Value({
  className,
  intent,
  icon,
  label,
  labelDescription,
  value,
  valueDescription,
  link,
  htmlFor,
  standalone,
  inline,
  numeric,
  ...props
}: ValueProps): ReactElement {
  let style: ValueStyle | null = null;
  if (intent) {
    style = valueStyles[intent];
  }
  const actualIcon = icon ?? style?.icon;

  let labelContent: ReactNode = label;
  if (link) {
    labelContent = <Link to={link}>{label}</Link>;
  }
  if (htmlFor) {
    labelContent = <label htmlFor={htmlFor}>{label}</label>;
  }

  const contents = (
    <tr className={classNames('value', style?.className, className)} {...props}>
      {label ? (
        <>
          <td className="value-label">
            {actualIcon && <FontAwesomeIcon icon={actualIcon} />}
            {labelContent}
            {labelDescription && (
              <span className="value-label-description">
                {labelDescription}
              </span>
            )}
          </td>
          <td
            className={classNames(
              'value-data',
              numeric ? 'value-data-numeric' : ''
            )}
          >
            {value}
            {valueDescription && (
              <span className="value-data-description">{valueDescription}</span>
            )}
          </td>
        </>
      ) : (
        <td
          className={classNames(
            'value-data',
            numeric ? 'value-data-numeric' : ''
          )}
          colSpan={2}
        >
          {actualIcon && <FontAwesomeIcon icon={actualIcon} />}
          {value}
          {valueDescription && (
            <span className="value-data-description">{valueDescription}</span>
          )}
        </td>
      )}
    </tr>
  );

  return standalone ? (
    <ValueList inline={inline} className={className}>
      {contents}
    </ValueList>
  ) : (
    contents
  );
}
