import { clamp, lerp } from '@basementuniverse/utils';
import { ShaderCanvas } from 'shader-canvas';
import HttpImage from '../images/http.svg';

/**
 * Logo animation
 *
 * > └[∵┌] └[ ∵ ]┘ [┐∵]┘
 */
const logo = (mainCanvas: HTMLCanvasElement, bg: string) => {
  let loaded = false;
  let mainContext: CanvasRenderingContext2D | null = null;
  let offscreenCanvas: HTMLCanvasElement | null = null;
  let offscreenContext: CanvasRenderingContext2D | null = null;
  let shaderCanvas: ShaderCanvas | null = null;
  let logoWidth = 0;
  let logoHeight = 0;
  let logoAspectRatio = 0;
  let time = 0;
  let amount = 0;

  const LOGO_WIDTH = 0.8;
  const MIN_AMOUNT = 0;
  const MAX_AMOUNT = 0.7;

  const shader = `
  precision mediump float;

  uniform float uTime;
  uniform float uAmount;
  uniform vec2 uResolution;
  uniform sampler2D uTexture;

  float time;

  float round(float n) {
    return floor(n + .5);
  }

  vec2 round(vec2 n) {
    return floor(n + .5);
  }

  float rand(vec2 co){
    return fract(dot(co.xy, vec2(12.9898, 78.233)) * 43758.5453);
  }

  const float glitchScale = .5;

  vec2 glitchCoord(vec2 p, vec2 gridSize) {
    vec2 coord = floor(p / gridSize) * gridSize;
    coord += (gridSize / 2.);
    return coord;
  }

  struct GlitchSeed {
    vec2 seed;
    float prob;
  };

  GlitchSeed glitchSeed(vec2 p, float speed) {
    float seedTime = floor(time * speed);
    vec2 seed = vec2(
      1. + mod(seedTime / 100., 100.),
      1. + mod(seedTime, 100.)
    ) / 100.;
    seed += p;

    float prob = uAmount;

    return GlitchSeed(seed, prob);
  }

  float shouldApply(GlitchSeed seed) {
    return round(
      mix(
        mix(rand(seed.seed), 1., seed.prob - .5),
        0.,
        (1. - seed.prob) * .5
      )
    );
  }

  vec4 swapCoords(vec2 seed, vec2 groupSize, vec2 subGrid, vec2 blockSize) {
    vec2 rand2 = vec2(rand(seed), rand(seed + .1));
    vec2 range = subGrid - (blockSize - 1.);
    vec2 coord = floor(rand2 * range) / subGrid;
    vec2 bottomLeft = coord * groupSize;
    vec2 realBlockSize = (groupSize / subGrid) * blockSize;
    vec2 topRight = bottomLeft + realBlockSize;
    topRight -= groupSize / 2.;
    bottomLeft -= groupSize / 2.;
    return vec4(bottomLeft, topRight);
  }

  float isInBlock(vec2 pos, vec4 block) {
    vec2 a = sign(pos - block.xy);
    vec2 b = sign(block.zw - pos);
    return min(sign(a.x + a.y + b.x + b.y - 3.), 0.);
  }

  vec2 moveDiff(vec2 pos, vec4 swapA, vec4 swapB) {
    vec2 diff = swapB.xy - swapA.xy;
    return diff * isInBlock(pos, swapA);
  }

  void swapBlocks(
    inout vec2 xy,
    vec2 groupSize,
    vec2 subGrid,
    vec2 blockSize,
    vec2 seed,
    float apply
  ) {
    vec2 groupOffset = glitchCoord(xy, groupSize);
    vec2 pos = xy - groupOffset;

    vec2 seedA = seed * groupOffset;
    vec2 seedB = seed * (groupOffset + .1);

    vec4 swapA = swapCoords(seedA, groupSize, subGrid, blockSize);
    vec4 swapB = swapCoords(seedB, groupSize, subGrid, blockSize);

    vec2 newPos = pos;
    newPos += moveDiff(pos, swapA, swapB) * apply;
    newPos += moveDiff(pos, swapB, swapA) * apply;
    pos = newPos;

    xy = pos + groupOffset;
  }

  void staticNoise(inout vec2 p, vec2 groupSize, float grainSize, float contrast) {
    GlitchSeed seedA = glitchSeed(glitchCoord(p, groupSize), 5.);
    seedA.prob *= .5;
    if (shouldApply(seedA) == 1.) {
      GlitchSeed seedB = glitchSeed(glitchCoord(p, vec2(grainSize)), 5.);
      vec2 offset = vec2(rand(seedB.seed), rand(seedB.seed + .1));
      offset = round(offset * 2. - 1.);
      offset *= contrast;
      p += offset;
    }
  }

  void freezeTime(vec2 p, inout float time, vec2 groupSize, float speed) {
    GlitchSeed seed = glitchSeed(glitchCoord(p, groupSize), speed);
    if (shouldApply(seed) == 1.) {
      float frozenTime = floor(time * speed) / speed;
      time = frozenTime;
    }
  }

  void glitchSwap(inout vec2 p) {
    vec2 pp = p;

    float scale = glitchScale;
    float speed = 25.;

    vec2 groupSize;
    vec2 subGrid;
    vec2 blockSize;
    GlitchSeed seed;
    float apply;

    groupSize = vec2(.6) * scale;
    subGrid = vec2(2);
    blockSize = vec2(1);

    seed = glitchSeed(glitchCoord(p, groupSize), speed);
    apply = shouldApply(seed);
    swapBlocks(p, groupSize, subGrid, blockSize, seed.seed, apply);

    groupSize = vec2(.8) * scale;
    subGrid = vec2(3);
    blockSize = vec2(1);

    seed = glitchSeed(glitchCoord(p, groupSize), speed);
    apply = shouldApply(seed);
    swapBlocks(p, groupSize, subGrid, blockSize, seed.seed, apply);

    groupSize = vec2(.2) * scale;
    subGrid = vec2(6);
    blockSize = vec2(1);

    seed = glitchSeed(glitchCoord(p, groupSize), speed);
    float apply2 = shouldApply(seed);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 1.), apply * apply2);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 2.), apply * apply2);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 3.), apply * apply2);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 4.), apply * apply2);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 5.), apply * apply2);

    groupSize = vec2(1.2, .2) * scale;
    subGrid = vec2(9,2);
    blockSize = vec2(3,1);

    seed = glitchSeed(glitchCoord(p, groupSize), speed);
    apply = shouldApply(seed);
    swapBlocks(p, groupSize, subGrid, blockSize, seed.seed, apply);
  }

  void glitchStatic(inout vec2 p) {
    staticNoise(p, vec2(.5, .25/2.) * glitchScale, .2 * glitchScale, 2.);
  }

  void glitchTime(vec2 p, inout float time) {
    freezeTime(p, time, vec2(.5) * glitchScale, 2.);
  }

  void main(void) {
    time = uTime / 2.;
    time = mod(time, 1.);

    vec2 uv = gl_FragCoord.xy / uResolution;

    // Glitching
    glitchSwap(uv);
    glitchTime(uv, time);
    glitchStatic(uv);

    // Chromatic aberration
    float aberrationAmount = 0.0;
    aberrationAmount = (1.0 + sin(uTime * 6.0)) * 0.5;
    aberrationAmount *= 1.0 + sin(uTime * 16.0) * 0.5;
    aberrationAmount *= 1.0 + sin(uTime * 19.0) * 0.5;
    aberrationAmount *= 1.0 + sin(uTime * 27.0) * 0.5;
    aberrationAmount *= 0.01 * uAmount;

    vec3 col;
    col.r = texture2D(uTexture, vec2(uv.x + aberrationAmount, uv.y)).r;
    col.g = texture2D(uTexture, uv).g;
    col.b = texture2D(uTexture, vec2(uv.x - aberrationAmount, uv.y)).b;
    col *= (1.0 - aberrationAmount * 0.5);

    gl_FragColor = vec4(col, 1.0);
  }
  `;

  const logo = new Image();
  logo.src = HttpImage;
  logo.onload = () => {
    logoWidth = logo.naturalWidth;
    logoHeight = logo.naturalHeight;
    logoAspectRatio = logoWidth / logoHeight;
    loaded = true;
  };

  window.onmousemove = e => {
    const screen = { x: window.innerWidth, y: window.innerHeight };
    const mouse = { x: e.clientX, y: e.clientY };
    const delta = { x: mouse.x - screen.x / 2, y: mouse.y - screen.y / 2 };
    const distance = Math.sqrt(delta.x * delta.x + delta.y * delta.y);
    amount = 1 - clamp((distance / Math.min(screen.x, screen.y)) * 2);
    amount = lerp(MIN_AMOUNT, MAX_AMOUNT, amount);
  };

  function loop() {
    time += 1 / 60;
    draw();
    window.requestAnimationFrame(loop);
  }

  function draw() {
    if (
      !loaded ||
      !offscreenCanvas ||
      !offscreenContext ||
      !shaderCanvas ||
      !mainContext
    ) {
      return;
    }

    const width = mainCanvas.offsetWidth;
    const height = mainCanvas.offsetHeight;

    mainCanvas.width = width;
    mainCanvas.height = height;

    offscreenCanvas.width = width;
    offscreenCanvas.height = height;

    const logoWidth = width * LOGO_WIDTH;
    const logoHeight = logoWidth / logoAspectRatio;

    offscreenContext.clearRect(0, 0, width, height);
    offscreenContext.fillStyle = bg;
    offscreenContext.fillRect(0, 0, width, height);
    offscreenContext.drawImage(
      logo,
      Math.floor(width / 2 - logoWidth / 2),
      Math.floor(height / 2 - logoHeight / 2),
      Math.floor(logoWidth),
      Math.floor(logoHeight)
    );

    shaderCanvas.setSize(width, height);
    shaderCanvas.setTexture(
      'uTexture',
      offscreenCanvas as any as HTMLImageElement
    );
    shaderCanvas.setUniform('uResolution', shaderCanvas.getResolution());
    shaderCanvas.setUniform('uTime', time);
    shaderCanvas.setUniform('uAmount', amount);
    shaderCanvas.render();

    mainContext.clearRect(0, 0, width, height);
    mainContext.drawImage(shaderCanvas.domElement, 0, 0, width, height);
  }

  mainContext = mainCanvas.getContext('2d');
  offscreenCanvas = document.createElement('canvas');
  offscreenContext = offscreenCanvas.getContext('2d');
  shaderCanvas = new ShaderCanvas();

  const errors = shaderCanvas.setShader(shader);
  if (errors) {
    console.error(errors);
  }

  loop();
};

export default logo;
