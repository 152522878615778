import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import useTransition from 'react-transition-state';
import classNames from '../utilities/class-names';
import './Modal.scss';

export type ModalProps = {
  className?: string;
  children?: ReactNode;
  closable?: boolean;
  dismissable?: boolean;
  show?: boolean;
  small?: boolean;
  onClose?: () => void;
};

export function Modal({
  className,
  children,
  closable,
  dismissable,
  show,
  small,
  onClose,
}: ModalProps): ReactElement {
  const [state, toggle] = useTransition({
    timeout: 200,
    preEnter: true,
    initialEntered: show,
  });

  useEffect(() => {
    toggle(show);
  }, [toggle, show]);

  const handleClose = useCallback(() => {
    toggle(false);
    onClose?.();
  }, [toggle, onClose]);

  return createPortal(
    <div
      className={classNames(
        'modal',
        className,
        state.status,
        small ? 'modal-small' : ''
      )}
    >
      <div
        className="modal-overlay"
        onClick={() => dismissable && handleClose()}
      />
      <div className="modal-container">
        {closable && (
          <button className="modal-close-button" onClick={() => handleClose()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        <div className="modal-content">{children}</div>
      </div>
    </div>,
    document.body
  );
}
