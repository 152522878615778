import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Modal, TaskBar } from '.';
import { Button, ButtonProps } from './Button';
import { ModalProps } from './Modal';

type ConfirmProps = ModalProps & {
  title?: string;
  message: string;
  confirmButton?: ButtonProps;
  cancelButton?: ButtonProps;
  onConfirm?: () => void;
};

export function Confirm({
  title = '',
  message,
  confirmButton = {},
  cancelButton = {},
  onConfirm,
  ...props
}: ConfirmProps): ReactElement {
  return (
    <Modal
      show={!!props?.show}
      closable={props?.closable}
      dismissable={props?.dismissable}
      onClose={props?.onClose}
      small
    >
      <h2>{title}</h2>
      <p>{message}</p>
      <TaskBar
        right={
          <>
            <Button
              intent={cancelButton?.intent}
              label={cancelButton?.label ?? 'Cancel'}
              icon={cancelButton?.icon ?? faTimes}
              onClick={props?.onClose}
              secondary
            />
            <Button
              intent={confirmButton?.intent}
              label={confirmButton?.label ?? 'Confirm'}
              icon={confirmButton?.icon}
              onClick={() => {
                onConfirm?.();
                props?.onClose?.();
              }}
            />
          </>
        }
        background={false}
      />
    </Modal>
  );
}
