import { IconDefinition, faClock } from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { EventType } from '../types';
import classNames from '../utilities/class-names';
import eventTypeColours from '../utilities/event-type-colours';

type EventTypeTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
  colour: string;
};

const eventTypeTagStyles: {
  [key in EventType]: EventTypeTagStyle;
} = {
  system: {
    icon: faClock,
    className: 'event-type-tag-system',
    label: 'System',
    colour: eventTypeColours['system'],
  },
  unknown: {
    icon: faClock,
    className: 'event-type-tag-unknown',
    label: 'Unknown',
    colour: eventTypeColours['unknown'],
  },
  test: {
    icon: faClock,
    className: 'event-type-tag-test',
    label: 'Test',
    colour: eventTypeColours['test'],
  },
  'configuration-created': {
    icon: faClock,
    className: 'event-type-tag-configuration-created',
    label: 'Configuration created',
    colour: eventTypeColours['configuration-created'],
  },
  'configuration-updated': {
    icon: faClock,
    className: 'event-type-tag-configuration-updated',
    label: 'Configuration updated',
    colour: eventTypeColours['configuration-updated'],
  },
  'configuration-deleted': {
    icon: faClock,
    className: 'event-type-tag-configuration-deleted',
    label: 'Configuration deleted',
    colour: eventTypeColours['configuration-deleted'],
  },
  'configuration-executed': {
    icon: faClock,
    className: 'event-type-tag-configuration-executed',
    label: 'Configuration executed',
    colour: eventTypeColours['configuration-executed'],
  },
  'user-created': {
    icon: faClock,
    className: 'event-type-tag-user-created',
    label: 'User created',
    colour: eventTypeColours['user-created'],
  },
  'user-updated': {
    icon: faClock,
    className: 'event-type-tag-user-updated',
    label: 'User updated',
    colour: eventTypeColours['user-updated'],
  },
  'user-deleted': {
    icon: faClock,
    className: 'event-type-tag-user-deleted',
    label: 'User deleted',
    colour: eventTypeColours['user-deleted'],
  },
  'user-logged-in': {
    icon: faClock,
    className: 'event-type-tag-user-logged-in',
    label: 'Logged in',
    colour: eventTypeColours['user-logged-in'],
  },
  'user-logged-out': {
    icon: faClock,
    className: 'event-type-tag-user-logged-out',
    label: 'Logged out',
    colour: eventTypeColours['user-logged-out'],
  },
  'setting-updated': {
    icon: faClock,
    className: 'event-type-tag-setting-updated',
    label: 'Setting updated',
    colour: eventTypeColours['setting-updated'],
  },
};

type EventTypeTagProps = {
  eventType: EventType;
};

export function EventTypeTag({ eventType }: EventTypeTagProps): ReactElement {
  let { icon, className, label, colour } = eventTypeTagStyles[eventType];

  return (
    <Tag
      show
      className={classNames('event-type-tag', className)}
      icon={icon}
      colour={colour}
    >
      {label}
    </Tag>
  );
}
