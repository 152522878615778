import {
  IconDefinition,
  faCheck,
  faExclamation,
  faInfo,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useEffect } from 'react';
import { useTransition } from 'react-transition-state';
import { Intent } from '../types/intent';
import classNames from '../utilities/class-names';
import './Badge.scss';

const badgeStyles: {
  [key in Intent]: {
    icon: IconDefinition;
    className: string;
  };
} = {
  info: {
    icon: faInfo,
    className: 'badge-info',
  },
  success: {
    icon: faCheck,
    className: 'badge-success',
  },
  warning: {
    icon: faExclamation,
    className: 'badge-warning',
  },
  danger: {
    icon: faTimes,
    className: 'badge-danger',
  },
};

type BadgeProps = {
  intent?: Intent;
  icon?: boolean;
  count?: number;
  maxCount?: number;
  text?: string;
  shake?: boolean;
  pulse?: boolean;
  show?: boolean;
};

export function Badge({
  intent = 'info',
  icon = true,
  count,
  maxCount = 99,
  text,
  shake,
  pulse,
  show,
}: BadgeProps): ReactElement {
  const [state, toggle] = useTransition({
    timeout: 200,
    preEnter: true,
    initialEntered: show,
  });

  useEffect(() => {
    toggle(show);
  }, [toggle, show]);

  const style = badgeStyles[intent];
  const actualCount =
    count !== undefined ? Math.min(count, maxCount) : undefined;
  const actualText = [actualCount?.toString(), text].filter(Boolean).join(' ');

  return (
    <span
      className={classNames(
        'badge',
        style.className,
        state.status,
        shake ? 'badge-shake' : '',
        pulse ? 'badge-pulse' : ''
      )}
    >
      {icon && !actualText && <FontAwesomeIcon icon={style.icon} />}
      {actualText && <span className="badge-text">{actualText}</span>}
    </span>
  );
}
