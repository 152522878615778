import { ReactElement, ReactNode } from 'react';
import classNames from '../utilities/class-names';
import './TaskBar.scss';

type TaskBarProps = {
  className?: string;
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  background?: boolean;
  [key: string]: any;
};

export function TaskBar({
  className,
  left,
  center,
  right,
  background = true,
  ...props
}: TaskBarProps): ReactElement {
  return (
    <div
      className={classNames(
        'task-bar',
        background ? 'task-bar-background' : '',
        className
      )}
      {...props}
    >
      <div className="task-bar-left">{left}</div>
      <div className="task-bar-center">{center}</div>
      <div className="task-bar-right">{right}</div>
    </div>
  );
}
