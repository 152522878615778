const config = {
  name: 'HTTP Transformer',
  api: {
    url:
      process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:8000'
        : 'https://api.ok.ht',
  },
  debug: {
    showTestPage: false,
  },
  dashboardDays: 30,
  bulletinRefreshInterval: 300,
  pageDateFormat: 'D MMM YYYY HH:mm:ss',
  datePickerDateFormat: 'D MMM YYYY',
  chartDateFormat: 'D MMM',
};

export default config;
