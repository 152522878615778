import {
  faPowerOff,
  faUserAlt,
  faUserNinja,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Card, Switch, TaskBar, ValueList } from '.';
import config from '../config';
import { User } from '../types';
import formatDate from '../utilities/format-date';
import './UserCard.scss';

type UserCardProps = {
  user: User;
  [key: string]: any;
};

export function UserCard({ user, ...props }: UserCardProps): ReactElement {
  return (
    <Card className="user-card" {...props}>
      <TaskBar
        left={
          <div>
            <h2>
              <FontAwesomeIcon icon={user.admin ? faUserNinja : faUserAlt} />
              <Link to={`/users/${user.id}`}>
                {user.displayName || 'Anonymous'}
              </Link>
              <span className="extra">{user.username}</span>
            </h2>
            <ValueList
              values={{
                Id: user?.id ?? '',
                Created: user
                  ? formatDate(user.createdAt, config.pageDateFormat)
                  : '',
                'Last active':
                  user && user.lastActiveAt ? (
                    formatDate(user.lastActiveAt, config.pageDateFormat)
                  ) : (
                    <span className="extra">No activity</span>
                  ),
              }}
            />
          </div>
        }
        right={
          <>
            <Switch
              name={`user-${user.id}-activated`}
              label="Activated"
              icon={faPowerOff}
              color="#5cb85c"
              value={user.activated}
              readonly
            />
          </>
        }
      />
    </Card>
  );
}
