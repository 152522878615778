import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useId } from 'react';
import { createPortal } from 'react-dom';
import ReactSelect, {
  GroupBase,
  MenuProps,
  OptionProps,
  Props,
  components,
} from 'react-select';
import { Tooltip } from 'react-tooltip';
import classNames from '../utilities/class-names';
import './Select.scss';

export type SelectOptionProps<T = any> = {
  label: string;
  icon?: IconDefinition;
  extra?: string;
  value: T;
};

type SelectProps = {
  className?: string;
  disabled?: boolean;
};

const SelectMenu = (props: MenuProps) =>
  createPortal(
    <Tooltip
      id={(props.selectProps as any).menuTooltipId}
      clickable
      openOnClick
      className="select-menu-tooltip"
      globalCloseEvents={{
        clickOutsideAnchor: true,
        escape: true,
      }}
    >
      <components.Menu {...props} />
    </Tooltip>,
    document.body
  );

const SelectOption = (props: OptionProps) => (
  <components.Option {...props}>
    {(props.data as SelectOptionProps)?.icon && (
      <span className="select-option-icon">
        <FontAwesomeIcon icon={(props.data as SelectOptionProps).icon!} />
      </span>
    )}
    {(props.data as SelectOptionProps).label}
    {(props.data as SelectOptionProps)?.extra && (
      <span className="select-extra">
        {(props.data as SelectOptionProps).extra}
      </span>
    )}
  </components.Option>
);

const SingleValue = (props: any) => (
  <components.SingleValue {...props}>
    {(props.data as SelectOptionProps)?.icon && (
      <span className="select-option-icon">
        <FontAwesomeIcon icon={(props.data as SelectOptionProps).icon!} />
      </span>
    )}
    {(props.data as SelectOptionProps).label}
    {(props.data as SelectOptionProps)?.extra && (
      <span className="select-extra">
        {(props.data as SelectOptionProps).extra}
      </span>
    )}
  </components.SingleValue>
);

export function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  className,
  disabled,
  ...props
}: SelectProps & Props<Option, IsMulti, Group>): ReactElement {
  const tooltipId = `select-menu-${useId()}`;

  return (
    <div
      className={classNames('select-container', className)}
      data-tooltip-id={tooltipId}
      data-tooltip-place="bottom-start"
    >
      <ReactSelect
        classNamePrefix="select"
        components={{
          Menu: SelectMenu as any,
          Option: SelectOption as any,
          SingleValue: SingleValue as any,
        }}
        {...props}
        isDisabled={disabled}
        menuIsOpen={true}
        // @ts-ignore
        menuTooltipId={tooltipId}
      />
    </div>
  );
}
