import {
  faAddressCard,
  faRightFromBracket,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useId } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { Button } from '.';
import { useAuth } from '../hooks/auth';
import './UserMenu.scss';

export function UserMenu(): ReactElement {
  const { logout } = useAuth();
  const tooltipId = `user-menu-tooltip-${useId()}`;

  return (
    <>
      <button
        className="user-menu"
        data-tooltip-id={tooltipId}
        data-tooltip-place="bottom-end"
      >
        <FontAwesomeIcon icon={faUserAlt} />
      </button>
      {createPortal(
        <Tooltip id={tooltipId} clickable className="user-menu-tooltip">
          <Button
            icon={faAddressCard}
            label="Profile"
            link="/profile"
            secondary
          />
          <Button
            icon={faRightFromBracket}
            label="Logout"
            onClick={logout}
            secondary
          />
        </Tooltip>,
        document.body
      )}
    </>
  );
}
