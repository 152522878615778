import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
import HttpImage from '../images/http.svg';
import './Title.scss';

type TitleProps = {
  titleLink?: string;
};

export function Title({ titleLink = '/' }: TitleProps): ReactElement {
  return (
    <div className="title">
      <Link to={titleLink}>
        <img src={HttpImage} className="title-logo" alt={config.name} />
      </Link>
    </div>
  );
}
