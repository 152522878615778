import loadable from '@loadable/component';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ProtectedRoute, RedirectExecuteConfiguration } from './components';
import { AuthProvider } from './contexts/auth';
import { BulletinProvider } from './contexts/bulletin';

const Home = loadable(() => import('./routes/Home'));
const Login = loadable(() => import('./routes/Login'));
const Dashboard = loadable(() => import('./routes/Dashboard'));
const Test = loadable(() => import('./routes/Test'));
const Profile = loadable(() => import('./routes/Profile'));
const Configurations = loadable(() => import('./routes/Configurations'));
const Configuration = loadable(() => import('./routes/Configuration'));
const ConfigurationEditor = loadable(
  () => import('./routes/ConfigurationEditor')
);
const Events = loadable(() => import('./routes/Events'));
const Event = loadable(() => import('./routes/Event'));
const Settings = loadable(() => import('./routes/Settings'));
const Users = loadable(() => import('./routes/Users'));
const User = loadable(() => import('./routes/User'));
const UserEditor = loadable(() => import('./routes/UserEditor'));
const Documentation = loadable(() => import('./routes/Documentation'));

const App = () => {
  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <AuthProvider>
          <BulletinProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/login"
                element={
                  <ProtectedRoute
                    requireLoggedIn={false}
                    redirectPath="/dashboard"
                  >
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/test"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <Test />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/configurations"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Configurations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/configurations/create"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ConfigurationEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/configurations/:id"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Configuration />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/configurations/:id/edit"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ConfigurationEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/events"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Events />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/events/:id"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Event />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users/create"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <UserEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users/:id"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <User />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users/:id/edit"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <UserEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/documentation"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Documentation />
                  </ProtectedRoute>
                }
              />
              <Route path="/:uid" element={<RedirectExecuteConfiguration />} />
            </Routes>
          </BulletinProvider>
        </AuthProvider>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
