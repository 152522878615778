import { ReactElement } from 'react';
import Fl3xImage from '../images/fl3x.svg';
import GLImage from '../images/gl.svg';
import './Footer.scss';

type FooterProps = {
  showFl3xButton?: boolean;
  showMadeInJapan?: boolean;
  showGLButton?: boolean;
};

export function Footer({
  showFl3xButton = true,
  showMadeInJapan = true,
  showGLButton = true,
}: FooterProps): ReactElement {
  return (
    <footer className="footer">
      {showFl3xButton && (
        <div className="fl3x-button">
          <a href="https://fl3x.tech/" target="_blank" rel="noreferrer">
            <img src={Fl3xImage} alt="fl3x" />
          </a>
        </div>
      )}
      {showMadeInJapan && (
        <div className="gl-button">
          <a
            href="https://www.gordonlarrigan.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={GLImage} alt="gl" />
          </a>
        </div>
      )}
      {showGLButton && (
        <div className="made-in-japan">
          Made in{' '}
          <a href="https://www.pref.oita.jp/" target="_blank" rel="noreferrer">
            Japan
          </a>
        </div>
      )}
    </footer>
  );
}
