import { ReactElement } from 'react';
import { SCROLL_TARGET_CLASS_NAME } from '../hooks/scroll';

type ScrollTargetProps = {
  name: string;
};

export function ScrollTarget({ name }: ScrollTargetProps): ReactElement {
  return (
    <span
      className={SCROLL_TARGET_CLASS_NAME}
      data-name={name}
      style={{
        userSelect: 'none',
        pointerEvents: 'none',
        visibility: 'hidden',
      }}
    ></span>
  );
}
