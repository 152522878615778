import {
  IconDefinition,
  faCheckCircle,
  faCircleNotch,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { useTransition } from 'react-transition-state';
import { Intent } from '../types/intent';
import classNames from '../utilities/class-names';
import './Alert.scss';

const alertStyles: {
  [key in Intent]: {
    icon: IconDefinition;
    className: string;
  };
} = {
  info: {
    icon: faInfoCircle,
    className: 'alert-info',
  },
  success: {
    icon: faCheckCircle,
    className: 'alert-success',
  },
  warning: {
    icon: faExclamationTriangle,
    className: 'alert-warning',
  },
  danger: {
    icon: faExclamationCircle,
    className: 'alert-danger',
  },
};

type AlertProps = {
  intent?: Intent;
  children?: ReactNode;
  closable?: boolean;
  show?: boolean;
  loading?: boolean;
  onClose?: () => void;
};

export function Alert({
  intent = 'info',
  children,
  closable,
  show,
  loading,
  onClose,
}: AlertProps): ReactElement {
  const [state, toggle] = useTransition({
    timeout: 200,
    preEnter: true,
    initialEntered: show,
  });

  useEffect(() => {
    toggle(show);
  }, [toggle, show]);

  const close = useCallback(() => {
    toggle(false);
    onClose?.();
  }, [toggle, onClose]);

  const style = alertStyles[intent];

  return (
    <div className={classNames('alert', style.className, state.status)}>
      <span className="alert-icon">
        <FontAwesomeIcon
          icon={loading ? faCircleNotch : style.icon}
          spin={!!loading}
        />
      </span>
      {closable && (
        <button className="alert-close-button" onClick={() => close()}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
      <span className="alert-content">{children}</span>
    </div>
  );
}
