import {
  IconDefinition,
  faUserAlt,
  faUserNinja,
  faUserXmark,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Rating } from '.';
import { Configuration, User } from '../types';
import { SearchResult } from '../types/search-result';
import './SearchResultsMenuItem.scss';

type SearchResultsMenuItemProps = {
  item: SearchResult;
};

export function SearchResultsMenuItem({
  item,
}: SearchResultsMenuItemProps): ReactElement {
  const relevance = useMemo(
    () => (
      <Rating
        rating={item.relevance}
        maxRating={3}
        colours={{
          '0': '#d9534f',
          '0.5': '#f0ad4e',
          '1': '#5bc0de',
          '1.5': '#5cb85c',
        }}
      />
    ),
    [item.relevance]
  );

  const configurationItem = useMemo(() => {
    const entity = item.entity as Configuration;

    return (
      <Link to={`/configurations/${entity.id}`}>
        <div className="search-results-menu-item search-results-menu-item-configuration">
          <div className="search-results-menu-item-icon">
            <FontAwesomeIcon icon={faVial} />
          </div>
          <div className="search-results-menu-item-details">
            <span className="search-results-menu-item-id">{entity.id}</span>
            <span className="search-results-menu-item-name">
              {entity.alias ?? entity.uid}
            </span>
          </div>
          <div className="search-results-menu-item-relevance">{relevance}</div>
        </div>
      </Link>
    );
  }, [item, relevance]);

  const userItem = useMemo(() => {
    const entity = item.entity as User;

    let icon: IconDefinition;
    if (!entity.activated) {
      icon = faUserXmark;
    } else if (entity.admin) {
      icon = faUserNinja;
    } else {
      icon = faUserAlt;
    }

    return (
      <Link to={`/users/${entity.id}`}>
        <div className="search-results-menu-item search-results-menu-item-user">
          <div className="search-results-menu-item-icon">
            <FontAwesomeIcon icon={icon} />
          </div>
          <div className="search-results-menu-item-details">
            <span className="search-results-menu-item-id">{entity.id}</span>
            <span className="search-results-menu-item-name">
              {entity.username}
            </span>
          </div>
          <div className="search-results-menu-item-relevance">{relevance}</div>
        </div>
      </Link>
    );
  }, [item, relevance]);

  return (
    <>
      {item.type === 'configuration' && configurationItem}
      {item.type === 'user' && userItem}
    </>
  );
}
