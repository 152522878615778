import { useContext } from 'react';
import { BulletinContext } from '../contexts/bulletin';
import { BulletinContextState } from '../types';

export function useBulletin(): BulletinContextState {
  const context = useContext(BulletinContext);

  if (context === undefined) {
    throw new Error('useBulletin must be used within a BulletinProvider');
  }

  return context;
}
