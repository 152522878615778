import { ReactElement, useEffect, useRef } from 'react';
import theme from '../exports.module.scss';
import logo from '../utilities/logo';
import './Logo.scss';

const BACKGROUND_COLOUR = '#191D23';

export function Logo(): ReactElement {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas !== null) {
      logo(canvas, theme.brandBlack ?? BACKGROUND_COLOUR);
    }
  }, []);

  return <canvas id="logo" ref={canvasRef}></canvas>;
}
