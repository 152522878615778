import { faBug, faPowerOff, faVial } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Card, ConfigurationAccessTag, Switch, TaskBar, ValueList } from '.';
import config from '../config';
import { Configuration } from '../types';
import formatDate from '../utilities/format-date';
import './ConfigurationCard.scss';

type ConfigurationCardProps = {
  configuration: Configuration;
  [key: string]: any;
};

export function ConfigurationCard({
  configuration,
  ...props
}: ConfigurationCardProps): ReactElement {
  return (
    <Card className="configuration-card" {...props}>
      <TaskBar
        left={
          <div>
            <h2>
              <FontAwesomeIcon icon={faVial} />
              <Link to={`/configurations/${configuration.id}`}>
                {configuration.uid}
              </Link>
              {configuration.alias && (
                <span className="extra">{configuration.alias}</span>
              )}
            </h2>
            <ValueList
              values={{
                Id: configuration?.id ?? '',
                Created: configuration
                  ? formatDate(configuration.createdAt, config.pageDateFormat)
                  : '',
                'Last used':
                  configuration && configuration.lastUsedAt ? (
                    formatDate(configuration.lastUsedAt, config.pageDateFormat)
                  ) : (
                    <span className="extra">Not executed yet</span>
                  ),
                Access: configuration && (
                  <ConfigurationAccessTag access={configuration?.access} />
                ),
              }}
            />
          </div>
        }
        right={
          <>
            <Switch
              name={`configuration-${configuration.id}-debug`}
              label="Debug mode"
              icon={faBug}
              color="#f0ad4e"
              value={configuration.debug}
              readonly
            />
            <Switch
              name={`configuration-${configuration.id}-activated`}
              label="Activated"
              icon={faPowerOff}
              color="#5cb85c"
              value={configuration.activated}
              readonly
            />
          </>
        }
      />
    </Card>
  );
}
