import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import config from '../config';
import { useLocalStorage } from '../hooks/local-storage';
import { AuthContextState, AuthData } from '../types';

export const AuthContext = createContext<AuthContextState | undefined>(
  undefined
);

export function AuthProvider({
  children,
}: {
  children?: ReactNode;
}): ReactElement {
  let location = useLocation();

  const [cachedAuthData, setCachedAuthData, clearCachedAuthData] =
    useLocalStorage('auth', false);

  const [pending, setPending] = useState(false);
  const [authData, setAuthData] = useState<AuthData | null>({
    ...cachedAuthData,
    tokenExpiresAt: cachedAuthData?.tokenExpiresAt
      ? new Date(cachedAuthData.tokenExpiresAt)
      : null,
  });
  const [loggedIn, setLoggedIn] = useState(!!authData);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (
      !authData ||
      !authData.tokenExpiresAt ||
      authData.tokenExpiresAt.getTime() < new Date().getTime()
    ) {
      setLoggedIn(false);
      setAuthData(null);
      clearCachedAuthData();
    }
  }, [location, authData, clearCachedAuthData]);

  const login = useCallback(
    async (username: string, password: string): Promise<void> => {
      setPending(true);
      setError(null);

      fetch(`${config.api.url}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(await response.text()));
            return;
          }

          const { user, token, tokenExpiresAt } = await response.json();
          const authData: AuthData = {
            user,
            token,
            tokenExpiresAt: new Date(tokenExpiresAt),
          };

          if (cachedAuthData) {
            clearCachedAuthData();
          }
          setLoggedIn(!!token);
          setAuthData(authData);
          setCachedAuthData(authData);
          setError(null);
        })
        .catch(error => {
          setPending(false);
          setError(error);
        });
    },
    [cachedAuthData, setCachedAuthData, clearCachedAuthData]
  );

  const logout = useCallback(async (): Promise<void> => {
    setPending(true);
    setError(null);

    // Log the user out clientside regardless of the response
    clearCachedAuthData();
    setLoggedIn(false);
    setAuthData(null);

    fetch(`${config.api.url}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-token': authData?.token ?? '',
      },
    })
      .then(async response => {
        setPending(false);

        if (!response.ok) {
          setError(new Error(await response.text()));
          return;
        }

        setError(null);
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  }, [authData, clearCachedAuthData]);

  return (
    <AuthContext.Provider
      value={{
        pending,
        loggedIn,
        ...(authData ?? {}),
        error,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
